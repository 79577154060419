import { useState } from "react";
import styled from "styled-components";

const oldInviteCodes = [
  "ben.je",
  "bmwpropertyleads",
  "thelandguy",
  "allaboutproperty",
  "propertyinvestmentsuk",
  "ukpropertypeople",
  "progressiveproperty",
  "propertyfinanceclub",
  "everythingproperty",
  "ombmv",
  "propertyinvestorsuk",
  "liverpoolproperty",
  "ukbmvproperty",
  "ukpropertyforsale",
  "propertyinvestments",
  "propertyinvestorclub",
  "ukprobatepropertygroup",
  "ukprobateproperty",
  "propertyentrepreneur",
  "p.c",
  "ppnbm23",
];

const DropContainer = styled.div`
  background-color: white;
  padding: 10px 10px 10px 18px;
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  box-shadow: 5px 8px 23px 5px rgba(0, 0, 0, 0.55);
`;

const Left = styled.div`
  flex-grow: 2;
  text-align: left;
`;

const InviteCode = styled.input`
  // font-size: 110%;
  outline: none;
  border: none;
  width: 95%;
`;

const Button = styled.button`
  background-color: #4f8831;
  &:hover {
    background-color: #68cf30;
  }
  &:disabled {
    background-color: lightgrey;
  }
  color: white;
  border-radius: 5px;
  border: none;
  outline: none;
  // padding: 10px 15px;
  cursor: pointer;
  font-weight: bold;
  // font-size: 90%;

  @media (max-width: 478px) {
    font-size: 90%;
  }
  @media (min-width: 478px) {
    font-size: 80%;
  }
  @media (min-width: 767px) {
    font-size: 90%;
  }
  @media (min-width: 992px) {
    font-size: 90%;
  }
`;

const ComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 380px;
  @media (max-width: 478px) {
    font-size: 14px;
  }
  @media (min-width: 478px) {
    font-size: 14px;
  }
  @media (min-width: 767px) {
    font-size: 1.1em;
  }
  @media (min-width: 992px) {
    font-size: 0.9em;
  }
`;

const Spinner = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #a05e9e; /* Light purple */
  border-radius: 50%;
  animation: spin 2s linear infinite;

  width: 8px; // FOR SOME REASON MUST BE DOUBLED UP FOR WEBFLOW TO WORK
  height: 8px; // FOR SOME REASON MUST BE DOUBLED UP FOR WEBFLOW TO WORK

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const NeedCodeLink = styled.a`
  color: white;
  text-decoration: none;
  font-size: 90%;
  text-shadow: rgb(88 0 0) 0px 0px 2px, rgb(28 0 0) 0px 1px 2px;
  &:hover {
    color: white;
    text-shadow: none;
    text-decoration: underline;
  }
`;

const ErrorDisplay = styled.div`
  font-weight: bold;
  padding-top: 14px;
  font-size: 90%;
  color: #fcff5a;
  // a:hover {
  //   color: green;
  // }
  // text-shadow: rgb(88 0 0) 0px 0px 3px, rgb(28 0 0) 0px 1px 3px;
`;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  @media (max-width: 478px) {
    height: 2.5em;
  }
  @media (min-width: 478px) {
    height: 2.5em;
  }
  @media (min-width: 767px) {
    height: 2.5em;
  }
  @media (min-width: 992px) {
    height: 2.5em;
  }
`;

const ErrorMessageGeneric = ({ text }) => {
  return <div style={{ color: "red", margin: "8px 0" }}>{text}</div>;
};

const LegacyInviteCode = ({ initialInviteCode }) => {
  const [emailAddress, setEmailAddress] = useState("");
  const [isValidCode, setIsValidCode] = useState("inviteCodeOK");
  const [isCheckingCode, setIsCheckingCode] = useState(false);
  const [errorCode, setErrorCode] = useState("");

  // const validCodeEntered = () => {
  //   setIsValidCode("OK");
  //   window.analytics?.track("Valid Invite Code Entered", {
  //     initialInviteCode,
  //     inviteCode,
  //   });
  //   window.location.href = `https://app.landworth.org/join/${inviteCode}`;
  // };
  const validEmailEntered = (email) => {
    setIsValidCode("OK");
    window.analytics?.track("Valid Email Address Entered", {
      initialInviteCode,
      email,
    });
    if (!!initialInviteCode) {
      window.location.href = `https://app.landworth.org/join/${initialInviteCode}?email=${email}`;
    } else {
      window.location.href = `https://app.landworth.org/join?email=${email}`;
    }
  };

  const checkCode = (code) => {
    // window.analytics.track("Submit Invite Code", { invitationCode: code });

    setIsCheckingCode(true);

    // const randomMilliseconds = 800 + Math.random() * 700;
    // event.preventDefault()
    code = code.toString().toLowerCase();
    if (code.length === 0) {
      setErrorCode("Please enter your email address");
      setIsCheckingCode(false);
    } else if (/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/i.test(code)) {
      validEmailEntered(code);
      setIsCheckingCode(false);
      // } else if (oldInviteCodes.includes(code)) {
      //   setTimeout(() => {
      //     validCodeEntered();
      //     setIsCheckingCode(false);
      //   }, randomMilliseconds);
      // } else if (/^[a-z]{2,}\.[a-z]{2,}/i.test(code)) {
      //   setTimeout(() => {
      //     validCodeEntered();
      //     setIsCheckingCode(false);
      //   }, randomMilliseconds);
    } else {
      setErrorCode("Sorry, that's not a valid email address");
      setIsCheckingCode(false);
    }
  };

  const inviteCodeKeyPress = (event) => {
    if (event.keyCode === 13) {
      checkCode(emailAddress);
    }
  };

  return (
    <ComponentContainer>
      <ErrorDisplay>&nbsp;{errorCode}&nbsp;</ErrorDisplay>
      <DropContainer>
        <Left>
          <InviteCode
            type="text"
            name="inviteCode"
            id="mce-INVITE_CODE"
            placeholder={"Enter your email address..."}
            style={{ fontWeight: !!emailAddress ? "bold" : "normal" }}
            value={emailAddress}
            onChange={(e) => {
              setEmailAddress(e.target.value);
            }}
            onKeyDown={(e) => inviteCodeKeyPress(e)}
            disabled={isValidCode === "OK" ? true : false}
          />
        </Left>

        <div>
          <Button
            type="button"
            name="subscribe"
            id="mc-embedded-subscribe"
            onClick={() => {
              checkCode(emailAddress);
            }}
            disabled={isValidCode === "OK" ? true : false}
          >
            {isCheckingCode ? (
              <SpinnerContainer>
                <Spinner />
              </SpinnerContainer>
            ) : (
              <SpinnerContainer>JOIN</SpinnerContainer>
            )}
          </Button>
        </div>
      </DropContainer>
      {/* {inviteCode.length < 4 && (
        <div style={{ paddingTop: "6px" }}>
          <NeedCodeLink
            href="https://docs.google.com/forms/d/e/1FAIpQLSdOZt7WP70WFURCjQLxPnULZPiRldK6f9nkLwNlWG6atCYejg/viewform"
            target="_blank"
          >
            Join the waitlist
          </NeedCodeLink>
        </div>
      )} */}
    </ComponentContainer>
  );
};

export default LegacyInviteCode;
