import "./App.css";
import LegacyInviteCode from "./LegacyInviteCode";

function App() {
  const initialInviteCode = new URL(window.location).searchParams.get("r");
  if (initialInviteCode && initialInviteCode !== "undefined")
    document.cookie = `r=${initialInviteCode}`;

  let cookieCode = document.cookie
    .split("; ")
    .find((row) => row.startsWith("r="))
    ?.split("=")[1];

  const cleanedCookieCode =
    cookieCode && cookieCode !== "undefined" ? cookieCode : null;

  return (
    <div
      className="App"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <LegacyInviteCode
        initialInviteCode={initialInviteCode || cleanedCookieCode}
      />
    </div>
  );
}

export default App;
